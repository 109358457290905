<!-- resources/js/MyComponent.vue -->
<template>
    <div class="bg-white block w-full flex flex-wrap h-full relative mx-auto">
        <div class="relative h-full w-full md:w-2/5" >
            <div class="w-full h-full overflow-x-hidden">
                <div class="relative h-full w-full">
                    <div class="py-4 px-4 md:px-4 text-gray-700 gap-x-2 sm:block border-r-2 border-gray-200">
                        <div class="flex w-full mt-2 flex-wrap rounded-md shadow-sm">
                            <button @click="selectMode = 'rephrase'" :class="[selectMode == 'rephrase' ? 'text-[#0F2774] bg-[#77AFFB]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold bg-white border border-gray-200 rounded-s-lg hover:bg-[#77AFFB] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                Rephrase
                            </button>
                            <button @click="selectMode = 'formal'" :class="[selectMode == 'formal' ? 'text-[#0F2774] bg-[#77AFFB]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold bg-white border border-gray-200  hover:bg-[#77AFFB] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                Formal
                            </button>
                            <button @click="selectMode = 'casual'" :class="[selectMode == 'casual' ? 'text-[#0F2774] bg-[#77AFFB]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold bg-white border border-gray-200  hover:bg-[#77AFFB] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                Casual
                            </button>
                            <button @click="selectMode = 'improve'" :class="[selectMode == 'improve' ? 'text-[#0F2774] bg-[#77AFFB]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold bg-white border border-gray-200  hover:bg-[#77AFFB] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                Improve
                            </button>
                            <button @click="selectMode = 'simplify'" :class="[selectMode == 'simplify' ? 'text-[#0F2774] bg-[#77AFFB]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold bg-white border border-gray-200  hover:bg-[#77AFFB] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                Simplify
                            </button>
                        </div>
                        <div class="relative">
                            <textarea rows="8" v-model="rawtext" class="w-full border-gray-200 rounded-md mt-1 pr-8" placeholder="What your paragraph will be about?✨" :class="{ 'border-red-500': isTextareaEmpty }" @input="isTextareaEmpty = rawtext.trim() === ''"  @focus="isTextareaFocused = true" @blur="isTextareaFocused = false" required></textarea>
                            <button v-if="rawtext.trim() !== ''" class="absolute right-2 mt-3 mx-1 ">
                                <img :src="'/images/delete.svg'" @click="clearText" alt="delete.svg" width="22px" height="22px">
                            </button>
                            <button class="absolute bottom-4 right-1 mt-3 mx-1">
                                <img v-if="!isRecording" :src="'/images/microphone.svg'" @click="startSpeechRecognition" alt="microphone.svg" width="22px" height="22px">
                                <img v-if="isRecording" :src="'/images/mute_microphone.svg'" @click="stopSpeechRecognition" alt="microphone.svg" width="22px" height="22px">
                            </button>
                            <div v-if="!rawtext.trim()" class="absolute bottom-16 left-28 flex items-center justify-center">
                                <div v-if="!isTextareaFocused" @click="addText" class="cursor-pointer mx-2 border rounded-md px-2 py-3 flex flex-col justify-center items-center">
                                    <img :src="'/images/sample_text.svg'" width="30px" height="30px" alt="simple_text_image">
                                    <button class="text-xs font-medium text-[#0F2774]">Sample Text</button>
                                </div>
                                <div v-if="!isTextareaFocused" @click="pasteTextFromClipboard" class="cursor-pointer border rounded-md px-2 py-3 flex flex-col justify-center items-center">
                                    <img :src="'/images/paste_your_text.svg'" width="35px" height="35px" alt="paste_text_image">
                                    <button class="text-xs font-medium text-[#0F2774]">Paste Your Text</button>
                                </div>
                            </div>
                            <div v-if="isTextareaEmpty" class="text-xs text-red-500"> {{ errorMessage }} </div>
                        </div>

                        <div class="w-full flex">
                            <div class="w-1/2 flex">
                                <label  for="file" class="w-30 flex flex-row items-center px-4 py-2 rounded-lg border cursor-pointer bg-gradient-to-b from-blue-500 via-blue-500 to-blue-300 hover:from-blue-700 hover:via-blue-700 hover:to-blue-500 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="mr-1" fill="none">
                                        <rect x="0.625" y="0.625" width="14.75" height="14.75" rx="2.375" fill="white"/>
                                        <rect x="0.625" y="0.625" width="14.75" height="14.75" rx="2.375" stroke="#263238" stroke-width="1.25"/>
                                        <path d="M6 6L7.99996 4M10 6L7.99996 4M7.99996 4V10" stroke="#263238" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11 11V12H5V11" stroke="#263238" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span class="text-white text-sm font-semibold">upload file</span>
                                    <input  type="file" multiple name="fields" ref="file" accept=".doc,.docx,.pdf,.txt" id="file" class="hidden" @change="handleFileUpload"/>
                                </label>
                            </div>
                            <div class="w-1/2">
                                <p class="text-xs text-right text-gray-700 text-black">
                                    Character Count: {{ characterCount }}
                                    Word Count: {{ wordCount }}
                                </p>
                            </div>
                        </div>
                        <label class="text-sm font-semibold mt-3">Number of paragraphs</label>
                        <select v-model="numbers" class="mt-1 shadow appearance-none border-gray-200 rounded-md rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            <option value="1">1 variant</option>
                            <option value="2">2 variants</option>
                            <option value="3">3 variants</option>
                        </select>

                        <div class="w-full py-2">
                            <div id="cf-container"></div>
                        </div>
                        <button @click="doParaphrase()" :disabled="loading" :class="{'gap-x-2 flex text-base bg-gradient-to-b from-blue-500 via-blue-500 to-blue-300 hover:from-blue-700 hover:via-blue-700 hover:to-blue-500 text-white px-5 md:py-2 py-2 rounded-md': !(loading || wordCount > 500),
                        'gap-x-2 flex text-base bg-gradient-to-b from-blue-500 via-blue-500 to-blue-300 text-white px-5 md:py-2 py-2 rounded-md cursor-not-allowed': loading || characterCount > 500
                        }" type="button">
                            <svg v-if="loading" class="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 28 29" fill="none" class="my-auto">
                                <path d="M24.8975 8.66676L22.6993 10.865L18.2734 6.43906L20.4716 4.24086C21.0913 3.62123 21.8879 3.32617 22.6846 3.32617C23.4812 3.32617 24.2779 3.62123 24.8975 4.24086C26.122 5.46536 26.122 7.44226 24.8975 8.66676Z" fill="white"/>
                                <path opacity="0.4" d="M22.696 10.8644L5.71537 27.8451C4.49087 29.0696 2.51397 29.0696 1.28947 27.8451C0.064969 26.6206 0.064969 24.6437 1.28947 23.4192L18.2701 6.43848L22.696 10.8644Z" fill="#ECF8F9"/>
                                <path d="M10.8073 2.76693L11.4122 0.716266C11.4712 0.524477 11.4122 0.317935 11.2794 0.170405C11.1466 0.0228748 10.9106 -0.0361372 10.7188 0.0228748L8.66814 0.627748L6.61747 0.0228748C6.42568 -0.0361372 6.21914 0.0228748 6.07161 0.155652C5.92408 0.303182 5.87982 0.509724 5.93883 0.701513L6.52895 2.76693L5.92408 4.8176C5.86506 5.00939 5.92408 5.21593 6.05685 5.36346C6.20438 5.51099 6.41093 5.55525 6.60271 5.49624L8.66814 4.90612L10.7188 5.51099C10.7778 5.52575 10.8221 5.5405 10.8811 5.5405C11.0286 5.5405 11.1614 5.48149 11.2794 5.37822C11.4269 5.23069 11.4712 5.02414 11.4122 4.83235L10.8073 2.76693ZM4.90612 11.6187L5.51099 9.56807C5.57 9.37628 5.51099 9.16974 5.37822 9.02221C5.23069 8.87468 5.02414 8.83042 4.83235 8.88943L2.76693 9.47955L0.716266 8.87468C0.524477 8.81566 0.317935 8.87468 0.170405 9.00745C0.022875 9.15498 -0.0213843 9.36153 0.0376278 9.55332L0.627748 11.6187L0.0228748 13.6694C-0.0361373 13.8612 0.022875 14.0677 0.155652 14.2152C0.303182 14.3628 0.509724 14.407 0.701513 14.348L2.75218 13.7431L4.80285 14.348C4.84711 14.3628 4.90612 14.3628 4.96513 14.3628C5.11266 14.3628 5.24544 14.3038 5.36346 14.2005C5.51099 14.053 5.55525 13.8464 5.49624 13.6546L4.90612 11.6187ZM27.0356 18.9952L27.6405 16.9445C27.6995 16.7528 27.6405 16.5462 27.5077 16.3987C27.3602 16.2512 27.1536 16.2069 26.9618 16.2659L24.9112 16.8708L22.8605 16.2659C22.6687 16.2069 22.4622 16.2659 22.3146 16.3987C22.1671 16.5462 22.1228 16.7528 22.1819 16.9445L22.7867 18.9952L22.1819 21.0459C22.1228 21.2377 22.1819 21.4442 22.3146 21.5917C22.4622 21.7393 22.6687 21.7835 22.8605 21.7245L24.9112 21.1196L26.9618 21.7245C27.0061 21.7393 27.0651 21.7393 27.1241 21.7393C27.2716 21.7393 27.4044 21.6803 27.5224 21.577C27.67 21.4295 27.7142 21.2229 27.6552 21.0311L27.0356 18.9952Z" fill="white"/>
                            </svg>
                            Rewrite My Paragraph
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-1 h-auto p-1 bg-white border-l w-2/5">
            <QuillEditor theme="snow" v-model:content="editortext" contentType="text" toolbar="minimal" class="h-auto" />
            <div class="mt-2 absolute -bottom-5 right-1 mb-4 mr-4 [&_p]:text-sm flex" v-if="isResultShown">
                <p class="text-sm">Character Count: {{ resultCharacterCount }}</p>
                <p class="text-sm mx-2">Word Count: {{ resultWordCount }}</p>
            </div>
            <div>
                <div  v-if="isResultShown" class="justify-content-between absolute bottom-0 right-15 mr-4 flex">
                    <p class="mt-2">Rate your result</p>
                    <div class="rate-btn flex relative">
                        <!-- like button -->
                            <button type="button"  @mouseover="showTooltip"  @mouseleave="hideTooltip" @click="submitFeedback(2)" class="mx-0.5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17 17" fill="none">
                                    <path d="M4.74971 15.9997H2.74721C2.32281 16.0072 1.91031 15.8586 1.58821 15.5821C1.26601 15.3056 1.05661 14.9204 0.999712 14.4997V9.24973C1.05661 8.82903 1.26601 8.44386 1.58821 8.16735C1.91031 7.89083 2.32281 7.74223 2.74721 7.74973H4.74971M4.74971 15.9997V7.74973M4.74971 15.9997H13.2097C13.5715 16.0038 13.9225 15.877 14.1982 15.6427C14.4738 15.4084 14.6555 15.0824 14.7097 14.7247L15.7447 7.97473C15.7774 7.75975 15.7629 7.54024 15.7022 7.33146C15.6416 7.12256 15.5363 6.92946 15.3936 6.76536C15.2509 6.60126 15.0743 6.47016 14.8759 6.38116C14.6775 6.29216 14.4622 6.24726 14.2447 6.24976H9.99973V3.24976C9.99973 2.65296 9.76268 2.08066 9.34072 1.65876C8.91876 1.23676 8.34647 0.999759 7.74973 0.999759L4.74971 7.74973" stroke="#FFAC48" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>

                            <div id="tooltip-default" role="tooltip" :class="{'visible': isTooltipVisible, 'invisible': !isTooltipVisible}" class="absolute -top-8 -left-3 px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm">
                                I like this 😍
                                <div class="tooltip-arrow" data-popper-arrow></div>
                            </div>

                        <!-- dislike button -->
                            <button type="button" @mouseover="showTool"  @mouseleave="hideTool"  @click="submitFeedback(1)" class="mx-0.5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17 17" fill="none">
                                    <path d="M12.012 1.00027H14.0145C14.4389 0.992761 14.8514 1.14137 15.1735 1.41788C15.4957 1.69439 15.7051 2.07956 15.762 2.50027V7.75027C15.7051 8.17097 15.4957 8.55614 15.1735 8.83265C14.8514 9.10917 14.4389 9.25777 14.0145 9.25027H12.012M12.012 1.00027V9.25027M12.012 1.00027H3.55199C3.19024 0.996181 2.83921 1.12296 2.56356 1.35726C2.28791 1.59156 2.10623 1.91759 2.05199 2.27527L1.01699 9.02527C0.984355 9.24025 0.998855 9.45976 1.05948 9.66854C1.12011 9.87744 1.22541 10.0705 1.36809 10.2346C1.51078 10.3987 1.68743 10.5298 1.88581 10.6188C2.08419 10.7078 2.29956 10.7527 2.51699 10.7502H6.76199V13.7502C6.76199 14.347 6.99904 14.9193 7.421 15.3412C7.84296 15.7632 8.41525 16.0002 9.01199 16.0002L12.012 9.25027" stroke="#FFAC48" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div id="tooltip-default" role="tooltip" :class="{'visible': isTooltipsecondVisible, 'invisible': !isTooltipsecondVisible}" class="px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm">
                                Need Some Improvement 😑
                                <div class="tooltip-arrow" data-popper-arrow></div>
                            </div>
                    </div>
                </div>
            </div>
            <ErrorPopupComponent :errorMessage="serverErrorMessage" :errorHeader="yourErrorHeaderVariable"  ref="errorPopup" />
        </div>
        <ActionButtonsComponent v-if="isResultShown" :text-to-copy="editortext" :editortext="editortext"/>
    </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { ref } from "vue";
import axios from "axios";
import ActionButtonsComponent from '@/Components/ActionButtonsComponent.vue';
import ErrorPopupComponent from '@/Components/ErrorPopupComponent.vue';
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker';

export default {
    components: {
        QuillEditor,
        ActionButtonsComponent,
        ErrorPopupComponent
    },
    props: {
        translations: Object,
        userId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            sitekey: '0x4AAAAAAAfG4Tr6iUYG81ci',
            token: null,
            rawtext: '',
            editortext: '',
            loading: false,
            isTextareaEmpty: false,
            errorMessage: "This field is required.",
            serverErrorMessage: "",
            yourErrorHeaderVariable: "",
            isDropdownVisible: false,
            isDropdownVisibleCount: false,
            selectedOption: '1 Paragraph',
            selectMode:'rephrase',
            options: [
                '1 Paragraph',
                '2 Paragraph',
                '3 Paragraph',
            ],
            label: 'Paragraph',
            characterCount: 0,
            wordCount: 0,
            resultCharacterCount: 0,
            resultWordCount: 0,
            isResultShown: false,
            demoTextFlag: false,
            isTooltipVisible: false,
            isTooltipsecondVisible: false,
            numbers: 1,
            isTextareaFocused: false,
            recognition: null,
            isRecording: false,
        };
    },
    computed: {
        wordCountExceeded() {
            return this.characterCount > 500;
        },
    },
    mounted() {
        this.renderCf();
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeMount() {
        this.cfInit();
        document.removeEventListener('click', this.handleClickOutside);
    },
    watch: {
        editortext(newText) {
            this.resultCharacterCount = newText.length;
            this.resultWordCount = newText.trim().split(/\s+/).filter(word => word.length > 0).length;
        },
        rawtext(newText) {
            this.characterCount = newText.length;  // Count the character
            this.wordCount = newText.trim().split(/\s+/).filter(word => word.length > 0).length;
        },
    },
    methods: {
        async pasteTextFromClipboard() {
            try {
                const text = await navigator.clipboard.readText();
                this.rawtext += text;
            } catch (err) {
                console.error("Failed to read clipboard contents:", err);
            }
        },
        showTooltip() {
            this.isTooltipVisible = true;
        },
        hideTooltip() {
            this.isTooltipVisible = false;
        },
        showTool() {
            this.isTooltipsecondVisible = true;
        },
        hideTool() {
            this.isTooltipsecondVisible = false;
        },
        submitFeedback(rating) {
            const userId = this.userId;
            axios.post('/feedback', {
                user_id: userId,
                rating: rating,
                _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            })
                .then(response => {
                    console.log('Feedback submitted:', response.data);
                })
                .catch(error => {
                    console.error('Error submitting feedback:', error);
                });
        },

        startSpeechRecognition() {
            if (!('webkitSpeechRecognition' in window)) {
                console.error('Speech recognition is not supported in this browser.');
                return;
            }


            const recognition = new webkitSpeechRecognition();
            recognition.lang = 'en-US';
            recognition.interimResults = false;
            recognition.maxAlternatives = 1;

            // Start speech recognition
            recognition.start();
            this.isRecording = true;

            // When speech is recognized, append the result to the textarea
            recognition.onresult = (event) => {
                const transcript = event.results[0][0].transcript;
                this.rawtext += transcript + ' ';
            };

            // Handle recognition errors
            recognition.onerror = (event) => {
                console.error('Speech recognition error:', event.error);
                this.isRecording = false; // Stop recording on error
            };

            // When recognition ends, switch back to start button
            recognition.onend = () => {
                this.isRecording = false;
                console.log('Speech recognition ended');
            };

            // Store the instance of recognition for stopping later
            this.recognitionInstance = recognition;
        },
        stopSpeechRecognition() {
            if (this.recognitionInstance) {
                this.recognitionInstance.stop();  // Stop speech recognition
                this.isRecording = false;         // Switch back to the start button
            }
        },

    addText() {
        if (!this.rawtext.trim()) {
            this.rawtext += 'Welcome to our paragraph rewriter tool! Easily transform your text with just a click. Whether you need to enhance clarity, change tone, our advanced technology ensures your content remains unique and engaging. Try it now for seamless rewriting!';
            this.isTextareaEmpty = this.rawtext.trim() === '';
            this.demoTextFlag = true;
            }
        },
        clearText() {
            // Reset both editortext and rawtext to clear the text
            this.editortext = '';
            this.rawtext = '';
            this.isTextareaEmpty = false; // Reset any error flags
        },
        async handleFileUpload(event) {
            const files = event.target.files;
            if (files.length) {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                        // Handle DOCX file using mammoth.js
                        this.readDocxFile(file);
                    } else if (file.type === 'text/plain') {
                        // Handle TXT file using FileReader
                        this.readTxtFile(file);
                    }  else if (file.type === 'application/pdf') {
                        // Handle PDF file using pdf.js
                        this.readPdfFile(file);
                    }
                    else {
                        console.error('Unsupported file format. Please upload .docx files.');
                    }
                }
            }
        },

        readDocxFile(file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const arrayBuffer = event.target.result;

                // Use mammoth.js to convert the DOCX content to plain text
                mammoth.extractRawText({ arrayBuffer })
                    .then(result => {
                        this.rawtext = result.value; // Insert the extracted text into the rawtext data property
                    })
                    .catch(error => {
                        console.error('Error reading the DOCX file:', error);
                    });
            };

            reader.readAsArrayBuffer(file); // Read the DOCX file as an ArrayBuffer
        },

        readTxtFile(file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                this.rawtext = event.target.result;  // Insert the text content into `rawtext`
            };
            reader.readAsText(file);
        },

        async readPdfFile(file) {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const arrayBuffer = event.target.result;
                const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;

                let extractedText = '';
                for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                    const page = await pdf.getPage(pageNumber);
                    const textContent = await page.getTextContent();
                    const pageText = textContent.items.map(item => item.str).join(' ');
                    extractedText += `\nPage ${pageNumber}:\n${pageText}\n`;
                }

                this.rawtext = extractedText;
            };

            reader.readAsArrayBuffer(file);
        },

        cfInit() {
            const e = document.createElement("script"), t = "https://challenges.cloudflare.com/turnstile/v0/api.js";
            e.src = `${t}?onload=onloadTurnstileCallback&render=explicit`, e.async = !0, e.defer = !0, document.head.appendChild(e);
        },
        updateText(event, index) {
            // Update the text in the data model
            this.$set(this.editortext, index, event.target.innerText);
        },
        renderCf() {
            let _this = this;
            window.onloadTurnstileCallback = function () {
                turnstile.render('#cf-container', {
                    sitekey: _this.sitekey,
                    callback: function (token) {
                        _this.verify(token);
                    },
                });
            };
        },
        adjustHeight(event) {
            const textarea = event.target;
            textarea.style.height = 'auto'; // Reset height to auto to calculate the new height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scrollHeight
        },
        verify(token) {
            this.token = token;
        },
        toggleDropdown() {
            this.isDropdownVisible = !this.isDropdownVisible;
        },
        selectOption(option) {
            this.selectedOption = option;
            this.toggleDropdown();
        },
        handleClickOutside(event) {
            const dropdown = this.$refs.dropdown;
            const dropdownCount = this.$refs.dropdownCount;
            if (dropdown && !dropdown.contains(event.target)) {
                this.isDropdownVisible = false;
            }
            if (dropdownCount && !dropdownCount.contains(event.target)) {
                this.isDropdownVisibleCount = false;
            }
        },
        doParaphrase() {
            if (this.demoTextFlag){
                this.loading = true;
                this.editortext = "This is the paraphrased version of the demo text.";
                this.isResultShown = true;
                this.demoTextFlag = false;
            }
            else {
                this.loading = true;

                axios.post("/tool/paragraphRewriter", {
                    capcha_token: this.token,
                    rawtext: this.rawtext,
                    paragraphs: this.selectedOption,
                    lang: 'english',
                    mode: this.selectMode,
                    number_of_paragraphs: this.numbers,
                })
                    .then(response => {
                        console.log('Response:', response.data);
                        console.log('Response:', response.data.content);
                        this.loading = false;
                        if (Array.isArray(response.data.content)) {
                        // if (response.data.content.length) {
                            this.editortext = response.data.content.join('\n\n');
                            this.isResultShown = true;
                        }
                        turnstile.reset('#cf-container')
                    })
                    .catch(e => {
                        console.error('Error show:', e);
                        this.loading = false;
                        turnstile.reset('#cf-container');

                        const lettersOnly = /^[a-zA-Z\s]+$/;

                        if (e.response && e.response.status === 422) {
                            this.serverErrorMessage = e.response.data.message;
                            this.yourErrorHeaderVariable = "Error 422";
                        } else if (this.rawtext.trim() === '') {
                            this.errorMessage = "Input Text Required"; // Show validation message below textarea
                            this.isTextareaEmpty = true;
                            this.serverErrorMessage = "Input Text Required.";
                            this.yourErrorHeaderVariable = "Validation Error";
                            this.$refs.errorPopup.showError = true;
                        } else if (this.rawtext.trim().length < 6) {
                            this.errorMessage = "Content is too short, Please add at least 6 characters."; // Show validation message below textarea
                            this.isTextareaEmpty = true;
                            this.serverErrorMessage = "Content is too short, Please add at least 6 characters.";
                            this.yourErrorHeaderVariable = "Validation Error";
                            this.$refs.errorPopup.showError = true;
                        } else {
                            this.serverErrorMessage = "Something went wrong. Please try again and reload.";
                            this.yourErrorHeaderVariable = "Fatal Error";
                        }

                        // Show the error popup for other errors
                        this.$refs.errorPopup.showError = true;
                    });

            }

        }
    },
};
</script>
<style>
.ql-editor {
    height: 100% !important;
}
.ql-container {
    height: auto !important;
}
.ql-container.ql-snow {
    border: none!important;
}
.ql-toolbar.ql-snow{
    border: none!important;
    border-bottom: 1px solid rgb(229 231 235);
}


.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
}

.dropdown-content a {
    color: black;
    padding: 6px 16px;
    text-decoration: none;
    display: block;
    font-weight: 400;
}

.dropdown-content a:hover {
    background-color: #E2E2E2;
}

.show-dropdown .dropdown-content {
    display: block;
}

.dropdownCount {
    position: relative;
    display: inline-block;
}
.dropdown-content-count {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
}

.dropdown-content-count a {
    color: black;
    padding: 6px 16px;
    text-decoration: none;
    display: block;
    font-weight: 400;
}

.dropdown-content-count a:hover {
    background-color: #E2E2E2;
}

.show-dropdownCount .dropdown-content-count {
    display: block;
}

.show-dropdownCount .dropdown-content-count {
    display: block;
}
.textarea-scroll {
    width: 100%;
    height: 120px;
    overflow-y: auto;
    padding-bottom: 10px;
    resize: none;

}

</style>
